<template>
  <div>

    <PageTitle page-title="Sell Your Car" />

    <!--Contact-us-->
    <section class="contact_us section-padding">
      <div class="container">
        <div  class="row">
          <div class="col-md-12">
            <h3>Sell Your Car</h3>
            <p>We can quickly offer you a competitive price for your car. Just fill in the details below and send off the form.</p>
            <b-form ref="sellyourcarform"  @reset.prevent="onReset" v-if="show">
              <b-row class="mt-5 mb-3">
                <b-col>
                  <h5 class="b-2">Your Vehicle Details</h5>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <b-form-input
                      id="input-4"
                      v-model="form.make"
                      placeholder="Make"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-5" label-for="input-5">
                    <b-form-input
                      id="input-5"
                      v-model="form.model"
                      placeholder="Model"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-6" label-for="input-6">
                    <b-form-input
                      id="input-6"
                      v-model="form.registration"
                      placeholder="Registration"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-7" label-for="input-7">
                    <b-form-input
                      id="input-7"
                      v-model="form.mileage"
                      type="number"
                      placeholder="Mileage"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-8" label-for="input-8">
                    <b-form-input
                      id="input-8"
                      v-model="form.colour"
                      placeholder="Colour"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-9" label-for="input-9">
                  <b-form-select v-model="form.fueltype" :options="options"></b-form-select>
                  </b-form-group>
                  <b-form-group id="input-group-10" label-for="input-10">
                    <b-form-input
                      id="input-"
                      v-model="form.condition"
                      placeholder="Condition Description"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <h5 class="b-3">Your Details</h5>
                  <b-form-group id="input-group-2" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="form.name"
                      placeholder="Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-2" label-for="input-2">
                    <b-form-input
                      id="input-2"
                      v-model="form.telephone"
                      type="number"
                      placeholder="Telephone"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-3" label-for="input-3">
                    <b-form-input
                      id="input-3"
                      v-model="form.email"
                      type="email"
                      placeholder="Email"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <vue-recaptcha ref="recaptcha" sitekey="6LfVeaYaAAAAAE5p7jQVxP1MuF4_MWGaWOKeA0m5"
                size="invisible"
                :loadRecaptchaScript="true"
                @verify="onVerify"
                @expired="onExpired">
              </vue-recaptcha>
              <b-button @click="onSubmit" variant="primary" class="pull-right">Request Offer</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </section>
    <!-- /Contact-us--> 
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'SellYourCar',
  components: {
    PageTitle: () => import('../components/PageTitle'),
    VueRecaptcha,
  },
  data() {
      return {
        form: {
          name: '',
          telephone: '',
          email: null,
          make: null,
          model: null,
          registration: null,
          mileage: null,
          colour:  null,
          fueltype: 'petrol',
          condition: null,
        },
        options: [
          { value: 'petrol', text: 'Petrol' },
          { value: 'diesel', text: 'Diesel' },
          { value: 'hybrid', text: 'Hybrid' },
          { value: 'electric', text: 'Electric' },
        ],
        show: true,
      }
    },
    methods: {
      async onSubmit() {
        if (this.$refs[`sellyourcarform`].checkValidity()) {
          this.$refs[`recaptcha`].execute()
        } else {
          this.$refs[`sellyourcarform`].reportValidity()
        }
      },
      onReset() {
        // event.preventDefault()
        // Reset our form values
        this.form.name = ''
        this.form.telephone = ''
        this.form.email = null
        this.form.make = null
        this.form.model = null
        this.form.registration = null
        this.form.mileage = null
        this.form.colour = null
        this.form.fueltype = 'petrol'
        this.form.condition = null
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      async onVerify() {
        const payload = {
          name: this.form.name,
          telephone: this.form.telephone,
          email: this.form.email,
          make: this.form.make,
          model: this.form.model,
          registration: this.form.registration,
          mileage: this.form.mileage,
          colour: this.form.colour,
          fueltype: this.form.fueltype,
          condition: this.form.condition
        }

        // Send mail
        axios({
          method: 'post',
          url: 'https://us-central1-midlandautosales-c2dcf.cloudfunctions.net/app/sendMail',
          headers: {
            'Content-Type': 'application/json'
          },
          data: payload
        })
        .then(response => {
          this.onReset()
          alert('Enquiry sent successfully')
        })
        .catch(err => {
          console.error(err)
          alert('Enquiry failed to send')
        })
      },
      onExpired() {
        this.$refs[`recaptcha`].reset()
      },
    }
}
</script>

<style scoped>
.page-header {
  background-image: url('../../public/assets/images/midland-auto-sales-sell-your-car-banner.jpg');
}
</style>
